<template>
  <div class="workout-categories section-padding">
    <div class="container">
      <div class="workout-categories-heading d-flex justify-content-between align-items-center">
        <p class="section-title">Treinar por</p>
        <router-link :to="{ name: 'workout-by-equipment'}" class="heading-link">Ver todos</router-link>
      </div>
      <div class="workout-categories-list">
        <div class="row workout-categories-list-row">
          <div class="col-4 workout-categories-list-column">
            <div class="workout-categories-list-item">
              <router-link :to="{ name: 'workout-by-list-category', params:{id: equipment.slug, pageTitle: equipment.title}}" class="d-flex justify-content-center align-items-center flex-column flex-lg-row">
                <div class="workout-categories-list-item-icon">
                  <weightIcon/>
                </div>
                <p class="workout-categories-list-item-text">{{ equipment.title }}</p>
              </router-link>
            </div>
          </div>
          <div class="col-4 workout-categories-list-column">
            <div class="workout-categories-list-item">
              <router-link :to="{ name: 'workout-by-sport', params:{id: sport.slug, pageTitle: sport.title}}" class="d-flex justify-content-center align-items-center flex-column flex-lg-row">
                <div class="workout-categories-list-item-icon">
                  <trophyIcon/>
                </div>
                <p class="workout-categories-list-item-text">{{ sport.title }}</p>
              </router-link>
            </div>
          </div>
          <div class="col-4 workout-categories-list-column">
            <div class="workout-categories-list-item">
              <router-link :to="{ name: 'workout-by-muscle', params:{id: muscularGroup.slug, pageTitle: muscularGroup.title}}" class="d-flex justify-content-center align-items-center flex-column flex-lg-row">
                <div class="workout-categories-list-item-icon">
                  <bodyIcon2/>
                </div>
                <p class="workout-categories-list-item-text">{{ muscularGroup.title }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import workoutItem from "@/components/workouts/workout-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import bodyIcon2 from "@/components/svg/body-icon-2.vue";
import weightIcon from "@/components/svg/weight-icon.vue";
import trophyIcon from "@/components/svg/trophy-icon.vue";
import axios from "axios";

export default {
  name: "workout-categories",
  data () {
    return {
      lang: this.$root.siteLang,
      muscularGroup: [],
      sport: [],
      equipment: [],
    }
  },
  components : {
    workoutItem,
    chevronLeft,
    bodyIcon2,
    weightIcon,
    trophyIcon
  },
  created() {
    //muscular group
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/cpc_2?with_category_title=0&with_seo=0&lang=' + this.lang)
        .then((response) => {
          this.muscularGroup = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});

    //sport
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/cpc_12?with_category_title=0&with_seo=0&lang=' + this.lang)
        .then((response) => {
          this.sport = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});

    //equipment
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/cpc_6?with_category_title=0&with_seo=0&lang=' + this.lang)
        .then((response) => {
          this.equipment = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>