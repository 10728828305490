<template>
  <div class="dashboard workout-list">
    <pageBanner/>
    <workoutsSlider/>
    <workoutCategories/>
    <workoutByAuthorSlider/>
    <!--<workoutByCoachSlider/>-->
    <workshopSlider/>
  </div>
</template>

<script>
//Components
import pageBanner from "@/components/page-banner.vue";
import workoutsSlider from "@/components/workouts/workouts-slider.vue";
import workoutCategories from "@/components/workouts/workout-categories.vue";
import workoutByAuthorSlider from "@/components/workouts/workout-by-author-slider.vue";
import workoutByCoachSlider from "@/components/workouts/workout-by-coach-slider.vue";
import workshopSlider from "@/components/workshop/workshop-slider.vue";
import axios from 'axios';

export default {
  name: "workout-list",
  data () {
    return {
      lang: this.$root.siteLang,
      workoutSliderItems: [],
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.treinos,
    }
  },
  components : {
    pageBanner,
    workoutsSlider,
    workoutCategories,
    workoutByAuthorSlider,
    workoutByCoachSlider,
    workshopSlider
  },
  created() {
    let authKey = window.$cookies.get("authKey");
    this.$root.pageTitle = this.$root.translations.treinos;

    axios
        .get(process.env.VUE_APP_API_URL + 'products?id_category=1&with_category_title=0&lang=' + this.lang,{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.workoutSliderItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>