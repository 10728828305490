<template>
  <div class="workouts-slider-wrapper section-padding">
    <div class="container" v-if="showWorkoutSlider">
      <div class="workouts-slider-holder">
        <div class="workouts-slider-heading d-flex justify-content-between align-items-center">
          <p class="section-title">Novos Treinos</p>
          <div class="d-flex justify-content-end align-items-center">
            <router-link :to="{ name: 'workout-by-list', params:{pageTitle: 'Novos Treinos'}}" class="heading-link">Ver todos</router-link>
            <div class="workouts-slider-arrows d-none d-xl-flex align-items-center justify-content-center">
              <button type="button" class="workouts-slider-arrow workouts-slider-prev" @click="prevSlide">
                <chevronLeft/>
              </button>
              <button type="button" class="workouts-slider-arrow workouts-slider-next" @click="nextSlide">
                <chevronLeft/>
              </button>
            </div>
          </div>
        </div>
        <Slick ref="slick" :options="slickOptions" class="workouts-slider">
          <div class="workouts-slider-item" v-for="(item,index) in workoutSliderItems" :key="index">
            <workoutItem :workoutItem="item"/>
          </div>
        </Slick>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import workoutItem from "@/components/workouts/workout-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import axios from 'axios';

export default {
  name: "workouts-slider",
  components : {
    Slick,
    workoutItem,
    chevronLeft
  },
  data() {
    return {
      lang: this.$root.siteLang,
      workoutSliderItems: [],
      showWorkoutSlider: false,
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true,
      }
    }
  },
  methods: {
    prevSlide: function() {
      this.$refs.slick.prev();
    },
    nextSlide: function() {
      this.$refs.slick.next();
    },
  },
  created() {
    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'products?id_category=1&with_category_title=0&lang=' + this.lang,{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.workoutSliderItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {
        (this.workoutSliderItems.length > 0) ? this.showWorkoutSlider = true : this.showWorkoutSlider = false;
    });
  },
}
</script>