<template>
  <div class="workout-by-wrapper section-padding">
    <div class="container">
      <div class="workout-by-heading d-flex justify-content-between align-items-center">
        <p class="section-title">Treino de Autor</p>
        <router-link :to="{ name: 'author-list'}" class="heading-link">Ver todos</router-link>
      </div>
      <Slick ref="slick" :options="slickOptions" class="workout-by-slider">
        <div class="workout-by-slider-item" v-for="(item,index) in AuthorSliderItems" :key="index">
          <router-link :to="{ name: 'author-detail', params:{id:$root.getLink(item)} }">
            <div class="workout-by-slider-image-wrapper" v-if="!!item.photo">
              <div class="workout-by-slider-image">
                <img :src="item.photo" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">{{ item.title }}</p>
          </router-link>
        </div>
      </Slick>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import axios from "axios";

export default {
  name: "workout-by-author-slider",
  components : {
    Slick,
  },
  data: function () {
    return {
      lang: this.$root.siteLang,
      errors: [],
      AuthorSliderItems: [],
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true
      }
    }
  },
  created() {
    axios
        .get(process.env.VUE_APP_API_URL + 'authors?lang=' + this.lang)
        .then((response) => {
          this.AuthorSliderItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>