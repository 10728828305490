<template>
  <div class="workout-by-wrapper section-padding">
    <div class="container">
      <div class="workout-by-heading d-flex justify-content-between align-items-center">
        <p class="section-title">Treino com PT</p>
        <a href="" class="heading-link">Ver todos</a>
      </div>
      <Slick ref="slick" :options="slickOptions" class="workout-by-slider">
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
        <div class="workout-by-slider-item">
          <a href="">
            <div class="workout-by-slider-image-wrapper">
              <div class="workout-by-slider-image">
                <img src="https://fakeimg.pl/72x72/" alt="">
              </div>
            </div>
            <p class="workout-by-slider-item-title">Kathryn</p>
          </a>
        </div>
      </Slick>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';

export default {
  name: "workout-by-coach",
  components : {
    Slick,
  },
  data: function () {
    return {
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true
      }
    }
  },
}
</script>